.stickyheader {
  position: sticky;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  z-index: 99;
  width: 100%;
  border-bottom: solid 1px var(--primary-color);
  top: 0;
  background: white;
}

.nav {
  padding: 15px;
  background-color: #0083f3!important;
  margin-top: 1%;
}
.navbar-dark{
  color: #fff;
  font-size: 18px;
} 

.navbar-nav{
  color: #fff;
  font-size: 18px;
} 
.nav-link {
  color: #fff !important;
  font-size: 18px;
}

.navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
  color: #eaea00;
}
.active {
  /* background-color: yellow; */
  color:#eaea00 !important;
}

.btn-bell-icon{
  display: none !important;
}
.bell-icon{
  position: relative;
}

.bell-badge {
  top: -10px !important;
  right: -20px;
  font-weight: 400;
  text-align: center;
  font-size: 1rem;
}

@media screen and (min-width:299px) and (max-width:479px) {
  .btn-bell-icon {
    display: block !important;
  }
}